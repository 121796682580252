import React from 'react';
import './CourseList.css';

// const CurrentCourses = [
//   { id: 1, name: 'Bachata nybörjare', day: 'Tisdagar', time: '18:00 - 19:30', place: 'Norra promenaden 108', dates: ["03/09", "10/09", "17/09", "24/09", "01/10", "08/10"]},
//   { id: 2, name: 'Bachata fortsättning del 1', day: 'Tisdagar', time: '19:30 - 21:00', place: 'Norra promenaden 108', dates: ["03/09", "10/09", "17/09", "24/09", "01/10", "08/10"]}
// ];

const CurrentCourses = [
  { id: 1, name: 'Salsa LA nivå 1', day: 'Måndagar', time: '18:00 - 19:30', place: 'Ljuragatan 1', dates: ["03/02", "10/02", "17/02", "24/02","03/03","10/03"] },
  { id: 2, name: 'Salsa LA nivå 2', day: 'Måndagar', time: '19:40 - 21:10', place: 'Ljuragatan 1', dates: ["03/02", "10/02", "17/02", "24/02","03/03","10/03"] },
  { id: 3, name: 'Bachata nivå 1', day: 'Tisdagar', time: '18:00 - 19:30', place: 'Ljuragatan 1', dates: ["04/02", "11/02", "18/02", "25/02","04/03","11/03"] },
  { id: 4, name: 'Bachata nivå 2', day: 'Tisdagar', time: '19:40 - 21:10', place: 'Ljuragatan 1', dates: ["04/02", "11/02", "18/02", "25/02","04/03","11/03"] },
  { id: 5, name: 'Fuerza 11-16+ år', day: 'Onsdagar', time: '18:00 - 19:00', place: 'Ljuragatan 1', dates: ["05/02", "12/02", "19/02", "26/02","05/03","12/03"] },
  { id: 6, name: 'Social dance', day: 'Onsdagar', time: '19:40 - 22:00', place: 'Ljuragatan 1', dates: ["05/02", "12/02", "19/02", "26/02","05/03","12/03"] },
  { id: 7, name: 'Kizomba Nivå 0', day: 'Torsdagar', time: '18:00 - 19:20', place: 'Ljuragatan 1', dates: ["06/02", "13/02", "20/02", "27/02","06/03","13/03"] },
  { id: 8, name: 'Kizomba Nivå 2', day: 'Torsdagar', time: '19:40 - 20:50', place: 'Ljuragatan 1', dates: ["06/02", "13/02", "20/02", "27/02","06/03","13/03"] },
  { id: 9, name: 'Fuerza 5-7+ år', day: 'Söndagar', time: '12:00 - 12:55', place: 'Ljuragatan 1', dates: ["09/02", "16/02", "23/02", "02/02","09/03","16/03"] },
  { id: 10, name: 'Fuerza 8-10+ år', day: 'Söndagar', time: '13:00 - 13:55', place: 'Ljuragatan 1', dates: ["09/02", "16/02", "23/02", "02/02","09/03","16/03"] },
  { id: 11, name: 'Fuerza 11-16+ år', day: 'Söndagar', time: '14:00 - 15:00', place: 'Ljuragatan 1', dates: ["09/02", "16/02", "23/02", "02/02","09/03","16/03"] },
  { id: 12, name: 'Salsa LA Nivå 0', day: 'Söndagar', time: '18:00 - 19:30', place: 'Ljuragatan 1', dates: ["09/02", "16/02", "23/02", "02/02","09/03","16/03"] },
  { id: 13, name: 'Bachata nivå 0', day: 'Söndagar', time: '19:40 - 21:10', place: 'Ljuragatan 1', dates: ["09/02", "16/02", "23/02", "02/02","09/03","16/03"] }
];


const UpcomingCourses = []

const groupByDay = (courses) => {
  return courses.reduce((grouped, course) => {
    const day = course.day;
    if (!grouped[day]) grouped[day] = [];
    grouped[day].push(course);
    return grouped;
  }, {});
};

function renderCoursesByDay(courses) {
  const groupedCourses = groupByDay(courses);

  return Object.keys(groupedCourses).map((day, index) => (
    <div key={index} className="course-item">
      <h5>{day}</h5>
      <div className="course-list">
        {groupedCourses[day].map((course) => (
          <div key={course.id} className="course-detail">
            <div className='kursDetalj' >Kurs: {course.name}</div>
            <div className='kursDetalj' >Tid: {course.time}</div>
            <div className='kursDetalj' >Plats: {course.place}</div>
            <div className='kursDetalj' >Kurs Tillfällen:</div>
            <div className="date-container">
              {course.dates.map((date, i) => (
                <span key={i} className="date-circle">{date}</span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  ));
}

function CourseList() {
  return (
    <div className='course-container'>
      <div className='current-courses'>
        <h2 className='course-list-title'>Nuvarande Kurser</h2>
        {renderCoursesByDay(CurrentCourses)}
      </div>

      <div className='upcoming-courses'>
        <h2 className='course-list-title'>Kommande Kurser</h2>
        {renderCoursesByDay(UpcomingCourses)}
      </div>
    </div>
  );
}

export default CourseList;
