import React from 'react';
import './Home.css';
import Album from '../components/Album';
import VideoPlayer from '../components/VideoPlayer';
import CountdownTimer from '../components/CountDown';

function Home() {
  return (
    <div className='home-container'>
      <section className='tajmer'>
        <h1 className='text-big'>Nedräkningen för dansfestivalen i april</h1>
        <CountdownTimer targetDate="2025-04-11T23:59:59" />
      </section>
    
      <img className='banner-bild' src="./Images/instruktorer/lulianochmajjo.jpeg" alt="Banner" />
     
      <section className='description'>
        <div className="home-text">
  <p>
    <strong>Bailamigos</strong> – Följ med oss på en resa av dans, glädje och utveckling.
  </p>
  <p>
    Bailamigos startades 2024 i Norrköping av Ricardo Gonzalez med en dröm om att skapa en plats där dans inte bara handlar om steg och rörelser, utan om en upplevelse som ger glädje, gemenskap och utveckling. Hos oss är det inte bara danslektioner – det är en resa där du kan växa som person, skapa minnen och känna dig som en del av något större.
  </p>
  <p><strong>Vad vi erbjuder:</strong></p>
  <ul>
    <li>Bachata och salsa-klasser för alla nivåer – från nybörjare som vill prova något nytt, till avancerade dansare som vill ta sin passion till nästa nivå.</li>
    <li>Lady styling där du kan hitta din egen stil, stärka din självkänsla och utveckla tekniker som gör dig tryggare på dansgolvet.</li>
    <li>Socialdanskvällar på onsdagar och helger – en möjlighet att öva, träffa nya vänner och ha kul i en avslappnad och välkomnande miljö.</li>
    <li>Workshops och event med gästinstruktörer från hela landet som ger nya perspektiv, idéer och inspiration.</li>
  </ul>
  <p>
    Vi samarbetar med FUERZA Dance Kids, där vi erbjuder barn och ungdomar en chans att hitta glädje i dans, bygga självförtroende och skapa minnen tillsammans. Vi gör också musikvideor för att fira framgångar och stärka teamkänslan, allt i en lekfull och inspirerande miljö.
  </p>
  <p><strong>Vår vision</strong></p>
  <p>
    Hos Bailamigos tror vi på dansens kraft att förena människor. Vi vill skapa en plats där alla är välkomna, oavsett ålder, bakgrund eller tidigare erfarenhet.
  </p>
  <p>
    Oavsett vem du är eller var du kommer ifrån, är du välkommen hos Bailamigos – där drömmar, dans och gemenskap möts.
  </p>
  <p><strong>Välkommen hem till Bailamigos!</strong></p>
</div>
      </section>
{/* 
      <section className='media-section'>
        <h2 className='section-title'>Bilder från festen</h2> */}
        {/* <Album />
        <h2 className='section-title'>Video från olika aktiviteter</h2>
        <VideoPlayer link={'Videos/eventCenter.mp4'} className="horizontal-video" />
        <div className="videoRow">
          <VideoPlayer link={'Videos/kursVideo.mp4'} className="vertical-video" />
          <VideoPlayer link={'Videos/eventCenterVertical.mp4'} className="vertical-video" />
          <VideoPlayer link={'Videos/kursHageby.mp4'} className="vertical-video" />
        </div> */}
      {/* </section> */}
    </div>
  );
}

export default Home;